import React, { useEffect, useState } from "react";
import "features/styles.css";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "react-bootstrap";
import compareAsc from "date-fns/compareAsc";
import DocumentsLinks from "components/DocumentsLinks";
import Timeline from "components/ChronoTimeline";
import FieldsListView from "components/FieldListView";
import TagMultiSelect from "components/TagMultiSelect";
import { setError } from "redux/workspaceSlice";
import Message from "./Message";
import Status from "./Status";
import { getParticipants } from "API/participants";
import { addTask, deleteTask, editTask, getTask } from "API/tasks";
import { setIsUpdateProject } from "redux/projectSlice";
import ConfirmModal from "components/ConfirmModal";
import ButtonSpinner from "components/ButtonSpinner";
import DatePicker, { registerLocale } from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import { deepEqualObj, formatDate, isJSONRegExp } from "services/helpers";
import DatePickerCustomInput from "components/DatePickerCustomInput";
import DraftEditor from "components/DraftEditor";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import useTabletWindow from "hooks/useTabletWindow";
import { dispatcOpenTaskLoading } from "redux/taskSlice";
import useMobileWindow from "hooks/useMobileWindow";
import TaskDetailRightSidebar from "features/task/TaskDetailRightSidebar";
import { parseEmailData, loadContent, saveContent } from "./helpers";
import MultiSelect from "components/MultiSelect";

const TaskDetail = ({ onClose, id, projectId }) => {
  registerLocale("en-US", enUS);
  const isTablet = useTabletWindow();
  const isMobile = useMobileWindow();

  const projects = useSelector((state) => state.workspaces.projects);
  const project = useSelector((state) => state.project.project);
  const { permission, name: projectName, date_from, date_to } = project;

  const projectDateFrom = formatDate(date_from);
  const projectDateTo = formatDate(date_to);
  const dispatch = useDispatch();
  const today = formatDate(new Date());

  const [name, setName] = useState("");
  const [isSubTask, setIsSubTask] = useState(false);
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [internalDescription, setInternalDescription] = useState(EditorState.createEmpty());
  const [currentProjectId, setCurrentProjectId] = useState("");
  const [owner, setOwner] = useState("0");
  const [dateFrom, setDateFrom] = useState(today);
  const [dateTo, setDateTo] = useState(today);
  const [deadline, setDeadline] = useState(today);
  const [isTaskClosed, setIsTaskClosed] = useState(false);
  const [taskFields, setTaskFields] = useState([]);

  const [taskTags, setTaskTags] = useState([]);
  const [taskTagsList, setTaskTagsList] = useState([]);

  const [loggerName, setLoggerName] = useState("");
  const [addedDate, setAddedDate] = useState("");
  const [closedDate, setClosedDate] = useState("");
  const [priority, setPriority] = useState();
  const [isActioned, setIsActioned] = useState();
  const [observers, setObservers] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [actionerIds, setActionersIds] = useState([]);
  const [fullParticipantsData, setFullParticipantsData] = useState([]);
  const [selecetedActioners, setSelecetedActioners] = useState([]);

  const [timelines, setTimelines] = useState([]);

  const [currentTaskId, setCurrentTaskId] = useState(null);
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorName, setErrorName] = useState("");
  // const [openCollapse, setOpenCollapse] = useState(false);
  const [isDocumentsLinks, setIsDocumentsLinks] = useState(true);
  const [initData, setInitData] = useState({});
  const [isSpinnerLoad, setIsSpinnerLoad] = useState(false);
  const [taskPermission, setTaskPermission] = useState(null);

  useEffect(() => {
    if (id !== null) {
      initTaskData(id);
      setCurrentTaskId(id);
    }
  }, [id]);

  const initTaskData = async (id) => {
    try {
      setLoading(true);
      const response = await getTask(id);
      if (response.data.success === 1) {
        await setParticipantsAsync();
        const task = response.data.task;
        setName(task.name);
        setTaskPermission(task.permission);
        setIsSubTask(Boolean(task.parent_task_id));
        setCurrentProjectId(task.projectISbb_t925_projectsID);
        setTimelines(task.timeline);
        // const parsedDescriptionData =
        //   task.detailsISsmallplaintextbox !== null && isJSONRegExp(task.detailsISsmallplaintextbox)
        //     ? EditorState.createWithContent(convertFromRaw(JSON.parse(task.detailsISsmallplaintextbox)))
        //     : task.detailsISsmallplaintextbox !== null
        //     ? EditorState.createWithContent(
        //         ContentState.createFromBlockArray(convertFromHTML(parseEmailData(task.detailsISsmallplaintextbox)))
        //       )
        //     : EditorState.createEmpty();
        const parsedDescriptionData =
          task.detailsISsmallplaintextbox !== null && isJSONRegExp(task.detailsISsmallplaintextbox)
            ? loadContent(task.detailsISsmallplaintextbox)
            : task.detailsISsmallplaintextbox !== null
            ? EditorState.createWithContent(
                ContentState.createFromBlockArray(convertFromHTML(parseEmailData(task.detailsISsmallplaintextbox)))
              )
            : EditorState.createEmpty();
        setDescription(parsedDescriptionData);

        // const parsedInternalDescription =
        //   task.internal_detailsISsmallplaintextbox !== null && isJSONRegExp(task.internal_detailsISsmallplaintextbox)
        //     ? EditorState.createWithContent(convertFromRaw(JSON.parse(task.internal_detailsISsmallplaintextbox)))
        //     : task.internal_detailsISsmallplaintextbox !== null
        //     ? EditorState.createWithContent(
        //         ContentState.createFromBlockArray(convertFromHTML(parseEmailData(task.internal_detailsISsmallplaintextbox)))
        //       )
        //     : EditorState.createEmpty();
        const parsedInternalDescription =
          task.internal_detailsISsmallplaintextbox !== null && isJSONRegExp(task.internal_detailsISsmallplaintextbox)
            ? loadContent(task.internal_detailsISsmallplaintextbox)
            : task.internal_detailsISsmallplaintextbox !== null
            ? EditorState.createWithContent(
                ContentState.createFromBlockArray(convertFromHTML(parseEmailData(task.internal_detailsISsmallplaintextbox)))
              )
            : EditorState.createEmpty();
        setInternalDescription(parsedInternalDescription);

        setDateFrom(task.date_from === null ? today : formatDate(task.date_from));
        setDateTo(task.date_to === null ? today : formatDate(task.date_to));
        setDeadline(task.date_deadline === null ? "" : formatDate(task.date_deadline));
        if (task.actioners.length > 0) {
          const actionerNames = task.actioners.map((item) => item.name);
          setSelecetedActioners(actionerNames);
          setActionersIds(task.actioners.map((item) => item._id));
        }
        setOwner(task.task_owner_id === null ? "0" : task.task_owner_id);
        setIsTaskClosed(task.task_closedYN === null ? false : task.task_closedYN === "1");

        const taskFieldsList = task.task_fields_list;
        setTaskFields(taskFieldsList === null ? [] : taskFieldsList.reverse());
        const taskTagsList = task.task_tags_list;
        setTaskTagsList(taskTagsList === null ? [] : taskTagsList);
        setTaskTags(task.task_tags === null ? [] : task.task_tags);

        setLoggerName(task.logger_name === null ? "" : task.logger_name);
        setAddedDate(task._dateadded === null ? "" : formatDate(task._dateadded));
        setClosedDate(task.datetime_closed === null ? "" : formatDate(task.datetime_closed));
        setPriority(!!parseFloat(task.priorityNUM));
        setIsActioned(!!parseFloat(task.task_actionedYN));
        const taskInitData = {
          task_id: id,
          name: task.name,
          detailsISsmallplaintextbox: task.detailsISsmallplaintextbox === null ? EditorState.createEmpty() : parsedDescriptionData,
          internal_detailsISsmallplaintextbox:
            task.internal_detailsISsmallplaintextbox === null ? EditorState.createEmpty() : parsedInternalDescription,
          task_owner_id: task.task_owner_id === null ? "0" : task.task_owner_id,
          date_from: task.date_from ? formatDate(task.date_from) : task.date_from,
          date_to: task.date_to ? formatDate(task.date_to) : task.date_to,
          date_deadline: task.date_deadline ? formatDate(task.date_deadline) : "",
          task_closedYN: task.task_closedYN === null ? 0 : task.task_closedYN === "1" ? 1 : 0,
          priorityNUM: +task.priorityNUM === 1 ? 1 : 0,
          task_actionedYN: +task.task_actionedYN === 1 ? 1 : 0
        };
        setInitData(taskInitData);
      } else if (response.data.error) {
        console.log(response.data.error, "response.data.error-initDataTask");
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: response.data.error
          })
        );
      }
      setLoading(false);
      dispatch(dispatcOpenTaskLoading(false));
    } catch (err) {
      console.log(err.toString(), "err.toString()-initDataTask");
      dispatch(
        setError({
          isShow: true,
          title: "Error",
          message: err.toString()
        })
      );
    }
  };

  const setParticipantsAsync = async () => {
    // console.log(actioners,'actioners-setPareticipantsAsync')
    await getParticipants(null, projectId)
      .then((res) => {
        if (res?.data?.error) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: res.data.error
            })
          );
        }
        const participantsData = res?.data?.list;
        setObservers(participantsData?.filter((item) => item.type === "Observer" && item.account_id !== null));
        const fullArrParticipants = participantsData?.filter((item) => item.type !== "Observer" && item.account_id !== null);
        setParticipants(fullArrParticipants.map((el) => el.account_name));
        setFullParticipantsData(fullArrParticipants);
      })
      .catch((err) => {
        console.log(err, "participanrs-task-detail-err");
        dispatch(
          setError({
            isShow: true,
            title: "Error",
            message: err?.message
          })
        );
      });
  };

  const onDoneTask = async () => {
    if (loading) {
      onClose();
      document.body.classList.add("overflow-auto");
      document.body.classList.remove("overflow-hidden");
    }
    if (currentTaskId && name === "") {
      setErrorName("The Title field is required");
      return;
    }
    const formData = {
      task_id: currentTaskId,
      name: name,
      detailsISsmallplaintextbox: saveContent(description),
      internal_detailsISsmallplaintextbox: saveContent(internalDescription),
      projectISbb_t925_projectsID: currentProjectId,
      task_owner_id: owner,
      actioner_id: actionerIds,
      date_from: dateFrom,
      date_to: dateTo,
      date_deadline: deadline,
      task_closedYN: isTaskClosed ? 1 : 0,
      priorityNUM: priority ? 1 : 0,
      task_actionedYN: isActioned ? 1 : 0
    };

    const changedFormData = { ...formData };
    changedFormData.detailsISsmallplaintextbox = description;
    changedFormData.internal_detailsISsmallplaintextbox = internalDescription;
    const isFormDataChanged = !deepEqualObj(initData, changedFormData);

    if (currentTaskId && isFormDataChanged) {
      setIsSpinnerLoad(true);
      await editTask(formData)
        .then((res) => {
          console.log(res, res.data, "edit-task");
          if (res.data.error) {
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: res.data.error
              })
            );
            return;
          }
          dispatch(setIsUpdateProject(true));
        })
        .catch((err) => {
          if (err.response.status) {
            console.log(err, err.response.status, "edit-project-err");
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: err.response.data.error
              })
            );
          }
        })
        .finally(() => setIsSpinnerLoad(false));
    }
    onClose();
    document.body.classList.add("overflow-auto");
    document.body.classList.remove("overflow-hidden");
  };

  const addNewTask = async () => {
    if (name === "") {
      setErrorName("The Title field is required");
      return;
    }
    const formData = {
      name: name,
      project_id: projectId,
      date_from: dateFrom,
      date_to: dateTo,
      date_deadline: deadline
    };
    if (currentTaskId === null) {
      await addTask(formData)
        .then((res) => {
          console.log(res, res.data, "add-tsk");
          if (res.data.error) {
            console.log(res.data.error, "add-task-res.data.error");
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: res.data.error
              })
            );
            return;
          }
          const newTaskId = res.data.new_task_id.toString();
          setCurrentTaskId(newTaskId);
          initTaskData(newTaskId);
          dispatch(setIsUpdateProject(true));
          setErrorName("");
        })
        .catch((err) => {
          if (err.response.status) {
            console.log(err, err.response.status, "add-task-catch err");
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: err.response.data.error
              })
            );
          }
        });
    }
  };

  const onDateFromChanged = (value) => {
    const formattedValue = formatDate(value);
    if (projectDateFrom) {
      let compareResult = compareAsc(new Date(projectDateFrom), new Date(formattedValue));
      if (compareResult > 0) {
        dispatch(
          setError({
            isShow: true,
            title: "Warning",
            message: `Date From in ${name} must match Date From in the ${projectName} ${formatDate(projectDateFrom)}`
          })
        );
        return;
      }
    }
    if (dateTo) {
      const compareResult = compareAsc(new Date(formattedValue), new Date(dateTo));
      if (compareResult > 0) {
        dispatch(
          setError({
            isShow: true,
            title: "Warning",
            message: "Date From value should be before DateTo value"
          })
        );
        return;
      }
    }
    setDateFrom(formattedValue);
  };

  const onDateToChanged = (value) => {
    const formattedValue = formatDate(value);
    if (deadline) {
      let compareResult = compareAsc(new Date(formattedValue), new Date(deadline));
      if (compareResult > 0) {
        dispatch(
          setError({
            isShow: true,
            title: "Warning",
            message: "The DateTo value should not be after the Deadline value"
          })
        );
        return;
      }
    }
    if (dateFrom) {
      const compareResult = compareAsc(new Date(dateFrom), new Date(formattedValue));
      if (compareResult > 0) {
        dispatch(
          setError({
            isShow: true,
            title: "Warning",
            message: "DateTo value should be after DateFrom value"
          })
        );
        return;
      }
    }
    setDateTo(formattedValue);
  };

  const onDateDeadlineChanged = (value) => {
    const formattedValue = formatDate(value);
    if (projectDateTo) {
      let compareResult = compareAsc(new Date(formattedValue), new Date(projectDateTo));
      if (compareResult > 0) {
        dispatch(
          setError({
            isShow: true,
            title: "Warning",
            message: `Deadline in ${name} must match Date To in the ${projectName} ${formatDate(projectDateTo)}`
          })
        );
        return;
      }
    }
    if (dateTo) {
      const compareResult = compareAsc(new Date(dateTo), new Date(formattedValue));
      if (compareResult > 0) {
        dispatch(
          setError({
            isShow: true,
            title: "Warning",
            message: "Deadline value should be after Date To value"
          })
        );
        return;
      }
    }
    setDeadline(formattedValue);
  };

  const onDeleteClicked = async () => {
    // formData.append("api_method", "delete_task");
    // formData.append("task_id", currentTaskId);
    await deleteTask({ task_id: currentTaskId })
      .then((res) => {
        // console.log(res.data, 'delete-task');
        if (res?.data?.error) {
          console.log(res?.data?.error, "delete-task-err-200");
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: res?.data?.error
            })
          );
          return;
        }
        dispatch(setIsUpdateProject(true));
      })
      .catch((err) => {
        if (err.response.status) {
          console.log(err, err.response.status, "delete-task-err");
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: err.response.data.error
            })
          );
        }
      })
      .finally(() => {
        setIsDeleteConfirm(false);
        onClose();
        document.body.classList.add("overflow-auto");
        document.body.classList.remove("overflow-hidden");
      });
  };

  const isPermission = permission === "Owner" || permission === "Admin" || permission === "Project Manager";
  // || (permission === "Observer" && taskPermission === "Owner");

  const isDisable = currentTaskId === null || !isPermission;

  const title = id === null ? "New Task" : isPermission ? `Edit Task TK(${id})` : `View Task TK(${id})`;

  const onCloseClick = () => {
    onClose();
    document.body.classList.add("overflow-auto");
    document.body.classList.remove("overflow-hidden");
  };

  const onActionerToChanged = (data) => {
    setSelecetedActioners(data.map((el) => el.value));
    const accountIds = fullParticipantsData
      .filter((item) => data.some((option) => option.value === item.account_name))
      .map((item) => item.account_id);
    setActionersIds(accountIds);
  };
  return (
    <>
      <div className="modal-container" onClick={onCloseClick}>
        <div
          className="modal-content"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Form>
            <div className="formWrapper" style={{ minHeight: "100%" }}>
              <div className="px-4 bg-white mainBlock">
                <div className="bg-white py-4 d-flex flex-column flex-md-row gap-2 justify-content-between align-items-center">
                  <span className="fs-5 fw-bold">{title}</span>
                  <div className="d-flex  align-items-center">
                    {id && (
                      <Button variant="warning" size="sm" onClick={onCloseClick} className="me-2">
                        Close
                      </Button>
                    )}
                    <ButtonSpinner onClick={onDoneTask} loading={isSpinnerLoad}>
                      Done
                    </ButtonSpinner>
                    {(isMobile || isTablet) && isPermission && (
                      <Button
                        variant="outline-danger"
                        className="ms-2 btn-sm"
                        onClick={() => setIsDeleteConfirm(true)}
                        style={{ float: "right" }}
                        disabled={isDisable}
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                </div>
                <div className="nameBlock mb-5">
                  <Form.Group className="nameInput">
                    <>
                      <Form.Label>Title</Form.Label>
                      {loading ? (
                        <div className="animated-background mx-2"></div>
                      ) : (
                        <Form.Control
                          type="text"
                          placeholder="Task title"
                          value={name}
                          onChange={(e) => {
                            if (e.target.value.length !== 0) setErrorName(null);
                            setName(e.target.value);
                          }}
                          onBlur={addNewTask}
                          className={errorName ? "border-danger" : ""}
                          disabled={currentTaskId && !isPermission}
                          style={{
                            backgroundColor: !isPermission ? "transparent" : ""
                          }}
                        />
                      )}
                      {errorName && (
                        <div className="text-danger" style={{ fontSize: "12px" }}>
                          {errorName}
                        </div>
                      )}
                    </>
                  </Form.Group>
                </div>
                <Form.Group className="mb-5" controlId="taskDescription">
                  <>
                    <Form.Label>Description</Form.Label>
                    {loading ? (
                      <div className="">
                        <div className="animated-background mx-2 my-1"></div>
                        <div className="animated-background mx-2 my-1"></div>
                        <div className="animated-background mx-2 my-1 w-50"></div>
                      </div>
                    ) : (
                      <>
                        <DraftEditor
                          value={description}
                          onDescriptionChange={(data) => setDescription(data)}
                          isDisable={currentTaskId === null}
                          isReadOnly={!isPermission}
                        />
                      </>
                    )}
                  </>
                </Form.Group>

                {(permission !== "Observer" ||
                  (currentTaskId === null && permission === "Observer") ||
                  (taskPermission === "Owner" && permission === "Observer")) && (
                  <Form.Group className="mb-5">
                    <>
                      <Form.Label>Internal Description</Form.Label>
                      {loading ? (
                        <div className="">
                          <div className="animated-background mx-2 my-1"></div>
                          <div className="animated-background mx-2 my-1"></div>
                          <div className="animated-background mx-2 my-1 w-50"></div>
                        </div>
                      ) : (
                        <DraftEditor
                          value={internalDescription}
                          onDescriptionChange={(data) => setInternalDescription(data)}
                          isDisable={currentTaskId === null}
                          isReadOnly={!isPermission}
                        />
                      )}
                    </>
                  </Form.Group>
                )}
                {loading ? (
                  <div className="d-flex flex-column my-2 w-100">
                    <Form.Label>Project</Form.Label>
                    <div className="animated-background mx-2"></div>
                  </div>
                ) : (
                  <Form.Group className="w-100 me-3 mb-5">
                    <Form.Label>Project</Form.Label>
                    <Form.Select
                      value={currentProjectId}
                      onChange={(e) => setCurrentProjectId(e.target.value)}
                      // id={getSelectId(isDisable, owner, taskPermission)}
                      id="selectId"
                      style={isDisable && currentTaskId !== null ? { backgroundColor: "transparent" } : {}}
                      disabled={isDisable}
                      required
                    >
                      {projects?.map((project) => (
                        <option value={project._id} key={project._id}>
                          {project.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                )}

                <div className="mb-5">
                  {loading ? (
                    <>
                      <div className="d-flex flex-column my-2 w-50">
                        <Form.Label>Owner/Customer</Form.Label>
                        <div className="animated-background mx-2"></div>
                      </div>
                      <div className="d-flex flex-column my-2 w-50">
                        <Form.Label>Actioners</Form.Label>
                        <div className="animated-background mx-2"></div>
                      </div>
                    </>
                  ) : (
                    <div className="selectsBlock">
                      <Form.Group className="selectItem me-3">
                        <Form.Label>Owner/Customer</Form.Label>
                        <Form.Select
                          value={owner}
                          onChange={(e) => setOwner(e.target.value)}
                          id={owner === "0" || owner === "" ? "readOnlyPlaceholderId" : "selectId"}
                          style={isDisable && currentTaskId !== null ? { backgroundColor: "transparent" } : {}}
                          disabled={isDisable}
                          required
                        >
                          <option value="" hidden>
                            Owner/Customer
                          </option>
                          <option value="">None</option>

                          {observers?.map((participant) => (
                            <option value={participant._id} key={participant._id}>
                              {participant.account_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="selectItem">
                        <Form.Label>Actioners</Form.Label>
                        <MultiSelect
                          onChange={(data) => onActionerToChanged(data)}
                          options={participants}
                          selected={selecetedActioners}
                          placeholder="Actioner"
                          disabled={isDisable}
                          required
                          style={{ height: "36px" }}
                        />
                        {/* <Form.Select
                          value={actioner}
                          onChange={(e) => setActioner(e.target.value)}
                          id={
                            // getSelectId(isDisable, actioner, taskPermission)
                            actioner === "0" || actioner === "" ? "readOnlyPlaceholderId" : "selectId"
                          }
                          style={!isPermission && currentTaskId !== null ? { backgroundColor: "transparent" } : {}}
                          disabled={isDisable}
                          required
                        >
                          <option value="" hidden>
                            Actioner
                          </option>
                          <option value="">None</option>
                          {participants?.map((participant) => (
                            <option value={participant.account_id} key={participant._id}>
                              {participant.account_name}
                            </option>
                          ))}
                        </Form.Select> */}
                      </Form.Group>
                    </div>
                  )}
                </div>
                {loading ? (
                  <>
                    <div className="animated-background mx-2 my-1" style={{ width: "30%" }}></div>
                    <div className="animated-background mx-2 my-1" style={{ width: "30%" }}></div>
                    <div className="animated-background mx-2 my-1" style={{ width: "30%" }}></div>
                  </>
                ) : (
                  <div className="datesBlock mb-5 ">
                    <Form.Group className="dateFrom mb-2 d-flex flex-column">
                      <Form.Label>Date From</Form.Label>
                      <DatePicker
                        selected={new Date(dateFrom)}
                        value={dateFrom}
                        onChange={onDateFromChanged}
                        disabled={isDisable}
                        // onCalendarClose={addEditTask}
                        formatWeekDay={(day) => day.slice(0, 3)}
                        locale="en-US"
                        placeholderText="Date from"
                        customInput={<DatePickerCustomInput isPermission={taskPermission !== null} />}
                        showYearDropdown
                      />
                    </Form.Group>

                    <Form.Group className="dateTo mb-2 d-flex flex-column">
                      <Form.Label>Date to</Form.Label>
                      <DatePicker
                        selected={new Date(dateTo)}
                        value={dateTo}
                        onChange={onDateToChanged}
                        disabled={isDisable}
                        // onCalendarClose={addEditTask}
                        formatWeekDay={(day) => day.slice(0, 3)}
                        locale="en-US"
                        placeholderText="Date to"
                        customInput={<DatePickerCustomInput isPermission={taskPermission !== null} />}
                        showYearDropdown
                      />
                    </Form.Group>
                    <Form.Group className="dateDeadline mb-2 d-flex flex-column">
                      <Form.Label>Deadline</Form.Label>
                      <DatePicker
                        selected={deadline ? new Date(deadline) : null}
                        value={deadline}
                        onChange={onDateDeadlineChanged}
                        disabled={isDisable}
                        // onCalendarClose={addEditTask}
                        formatWeekDay={(day) => day.slice(0, 3)}
                        locale="en-US"
                        placeholderText="Deadline"
                        customInput={<DatePickerCustomInput isPermission={taskPermission !== null} />}
                        showYearDropdown
                      />
                    </Form.Group>
                  </div>
                )}

                {loading ? (
                  <div className="animated-background mx-2"></div>
                ) : taskTags.length > 0 || isPermission ? (
                  <Form.Group className={taskFields.length !== 0 ? "" : "mb-5"}>
                    <>
                      <Form.Label>Tags</Form.Label>
                      <TagMultiSelect
                        taskId={currentTaskId}
                        projectId={projectId}
                        taskTagsList={taskTagsList}
                        savedTags={taskTags}
                        disabled={currentTaskId === null || taskPermission === "Actioner" || taskPermission === "Observer"}
                        readOnly={taskPermission === "Actioner" || taskPermission === "Observer"}
                      />
                    </>
                  </Form.Group>
                ) : null}

                {!!taskFields.length && <div className="task-hr my-4"></div>}

                {!!taskFields.length && (
                  <Form.Group>
                    <Form.Label>Fields</Form.Label>
                    <FieldsListView
                      taskId={currentTaskId}
                      projectId={projectId}
                      savedFields={taskFields}
                      disabled={currentTaskId === null}
                      isReadOnly={taskPermission === "Observer"}
                      taskPermission={taskPermission}
                    />
                  </Form.Group>
                )}

                {!!taskFields.length && <div className="task-hr mt-2 mb-4"></div>}

                <div className="switchBlock mb-5">
                  <Form.Group className="d-flex justify-content-between align-items-center fieldItem">
                    <Form.Label>Task Closed/Completed</Form.Label>
                    <label className="switch ms-3">
                      <input
                        type="checkbox"
                        checked={isTaskClosed}
                        value={isTaskClosed}
                        onChange={(e) => setIsTaskClosed(e.target.checked)}
                        disabled={isDisable}
                      />
                      <span className="slider round"></span>
                    </label>
                  </Form.Group>
                  <Form.Group className="d-flex justify-content-between align-items-center fieldItemPriority">
                    <Form.Label>Priority</Form.Label>
                    <label className="switch ms-3">
                      <input
                        type="checkbox"
                        checked={priority}
                        value={priority}
                        onChange={(e) => setPriority(e.target.checked)}
                        disabled={isDisable}
                      />
                      <span className="slider round"></span>
                    </label>
                  </Form.Group>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-5">
                  <Form.Group className="d-flex justify-content-between align-items-center fieldItem">
                    <Form.Label>Actioned</Form.Label>
                    <label className="switch ms-3">
                      <input
                        type="checkbox"
                        checked={isActioned}
                        value={isActioned}
                        onChange={(e) => setIsActioned(e.target.checked)}
                        disabled={currentTaskId === null || permission === "Observer"}
                      />
                      <span className="slider round"></span>
                    </label>
                  </Form.Group>
                </div>
                <Status
                  taskId={currentTaskId}
                  loggerName={loggerName}
                  addedDate={addedDate}
                  closedDate={closedDate}
                  permission={permission}
                  isSubTask={isSubTask}
                />

                <Form.Group className="w-100 my-5">
                  <Form.Label>Notes & Messages</Form.Label>
                  <Message taskId={currentTaskId} />
                </Form.Group>

                {(isMobile || isTablet) && (isPermission || isDocumentsLinks) && (
                  <Form.Group className="mb-5">
                    <Form.Label>Documents & Links</Form.Label>
                    <DocumentsLinks
                      isDisabled={currentTaskId === null}
                      taskId={currentTaskId}
                      setIsDocumentsLinks={setIsDocumentsLinks}
                      isPermission={isPermission}
                    />
                  </Form.Group>
                )}

                {!isMobile && timelines?.length > 0 && (
                  <Form.Group className="mb-5 w-100 timelineHorizontal">
                    <Form.Label className="mb-3">Timeline</Form.Label>
                    <Timeline values={timelines} mode="HORIZONTAL" />
                  </Form.Group>
                )}
              </div>
              <div className="bg-gray opacity-75" style={{ width: "10px" }}></div>
              <TaskDetailRightSidebar
                permission={project?.permission}
                timelines={timelines}
                isDocumentsLinks={isDocumentsLinks}
                setIsDocumentsLinks={setIsDocumentsLinks}
                loading={loading}
                setIsDeleteConfirm={setIsDeleteConfirm}
                id={id}
              />
            </div>
          </Form>
        </div>
      </div>
      <ConfirmModal
        isShow={isDeleteConfirm}
        title={"Delete Task"}
        message={"Are you sure you want to delete this task?"}
        onDelete={onDeleteClicked}
        onClose={setIsDeleteConfirm}
      />
    </>
  );
};

TaskDetail.propTypes = {
  onClose: PropTypes.func,
  id: PropTypes.string,
  projectId: PropTypes.string
};
export default TaskDetail;
