import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { FaExclamationTriangle } from "react-icons/fa";

const ConfirmModal = ({
  isShow,
  onDelete,
  onClose,
  title,
  message,
  children,
}) => {
  return (
    <Modal show={isShow} onHide={() => onClose(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-5">
        <div className="d-flex justify-content-center align-items-start">
          <div className="text-center">
            <span style={{ width: "20px", marginRight: "8px" }}>
              <FaExclamationTriangle className="text-warning" />
            </span>
            <span>{children ? children : message}</span>
          </div>
        </div>
        {children ? null : (
          <div className="d-flex justify-content-center mt-4">
            <Button
              variant="primary"
              className="mx-2"
              style={{ minWidth: 70 }}
              onClick={onDelete}
            >
              Yes
            </Button>
            <Button
              variant="secondary"
              className="mx-2 btn-sm"
              style={{ minWidth: 70 }}
              onClick={() => onClose(false)}
            >
              No
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
ConfirmModal.propTypes = {
  isShow: PropTypes.bool,
  onDelete: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.node,
};

export default ConfirmModal;
